// Generated by Framer (ae50a0d)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as sharedStyle from "../css/sWDjdymW6";
const MaterialFonts = getFonts(Material);

const serializationHash = "framer-776h6"

const variantClassNames = {IeR7gF9Xq: "framer-v-1fasy38"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({color, height, id, title, width, ...props}) => { return {...props, n0Hn0X9UM: color ?? props.n0Hn0X9UM ?? "var(--token-6cc103ad-e13f-46b2-b4f2-54b8157bc437, rgb(97, 1, 1))", VeeR0m7fb: title ?? props.VeeR0m7fb ?? "EN"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, VeeR0m7fb, n0Hn0X9UM, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "IeR7gF9Xq", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1fasy38", className, classNames)} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"IeR7gF9Xq"} ref={refBinding} style={{...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1lcpruv-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"F8PvzVIiM-container"} nodeId={"F8PvzVIiM"} rendersWithMotion scopeId={"Evliz4QHI"}><Material color={n0Hn0X9UM} height={"100%"} iconSearch={"Home"} iconSelection={"Language"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"F8PvzVIiM"} layoutId={"F8PvzVIiM"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider><motion.div className={"framer-1wtzff8"} layoutDependency={layoutDependency} layoutId={"RGBVKQI7h"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1liy3mn"} data-styles-preset={"sWDjdymW6"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-n0Hn0X9UM-Evliz4QHI))"}}>EN</motion.p></React.Fragment>} className={"framer-6ieit9"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"CS8ttNpj6"} style={{"--extracted-r6o4lv": "var(--variable-reference-n0Hn0X9UM-Evliz4QHI)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-n0Hn0X9UM-Evliz4QHI": n0Hn0X9UM}} text={VeeR0m7fb} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-776h6.framer-frq1od, .framer-776h6 .framer-frq1od { display: block; }", ".framer-776h6.framer-1fasy38 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 20px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 56px; }", ".framer-776h6 .framer-1lcpruv-container { flex: none; height: 18px; position: relative; width: 18px; }", ".framer-776h6 .framer-1wtzff8 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-776h6 .framer-6ieit9 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-776h6.framer-1fasy38, .framer-776h6 .framer-1wtzff8 { gap: 0px; } .framer-776h6.framer-1fasy38 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-776h6.framer-1fasy38 > :first-child { margin-left: 0px; } .framer-776h6.framer-1fasy38 > :last-child { margin-right: 0px; } .framer-776h6 .framer-1wtzff8 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-776h6 .framer-1wtzff8 > :first-child { margin-top: 0px; } .framer-776h6 .framer-1wtzff8 > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 56
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"VeeR0m7fb":"title","n0Hn0X9UM":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEvliz4QHI: React.ComponentType<Props> = withCSS(Component, css, "framer-776h6") as typeof Component;
export default FramerEvliz4QHI;

FramerEvliz4QHI.displayName = "Lang - txt";

FramerEvliz4QHI.defaultProps = {height: 20, width: 56};

addPropertyControls(FramerEvliz4QHI, {VeeR0m7fb: {defaultValue: "EN", displayTextArea: false, title: "Title", type: ControlType.String}, n0Hn0X9UM: {defaultValue: "var(--token-6cc103ad-e13f-46b2-b4f2-54b8157bc437, rgb(97, 1, 1))", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerEvliz4QHI, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...MaterialFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})